import { after } from "lodash";

/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: /thmall-operation/src/service/apis.js
 */
const isProduction = process.env.NODE_ENV === "production";
// 测试服务器后面加上sys
const apis = {
  queryShoppingRanking: "/mock/queryShoppingRanking",
  sendEmailCode: `/api/sys/register/sendEmail`,
  queryList: "/api/store/queryList",

  //颜星
  verifyCaptcha: `/api/sys/register/verifyCaptcha`, // 核对验证码
  registerEmailCode: `/api/sys/register/sendEmail`, // 发送邮箱验证码
  addUserInfo: `/api/sys/register/addUserInfo`, // 手机号邮箱注册 ---带密码
  getPhoneCode: `/api/sys/register/sendSms`, // 发送手机验证码
  getGoodsList: `/api/product/productBasic/operateProductList`, // 商品列表展示接口
  productOffShelf: `/api/product/productBasic/productOffShelf`, // 商品下架接口
  delProduct: `/api/product/productBasic/delProduct`, // 商品删除
  // 导航管理模块
  getNavManageList: `/api/product/productNavigation/queryProductNavigationInfo`, // 获取导航管理分页列表
  getNavVersionDetails: `/api/product/productNavigation/queryInfoById`, // 获取导航版本
  updateProductNavigation: `/api/product/productNavigation/updateProductNavigation`, // 编辑导航版本接口
  updateProductNavigationPublish: `/api/product/productNavigation/updateProductNavigationPublish`, // 删除,取消定时上架,上架
  copyTableRow: `/api/product/productNavigation/copy`, // table表格行数据复制
  // 推荐商品管理
  recommendProductList: `/api/product/recommendProductManagement/recommendProductList`, //列表接口
  enableAndDisable: `/api/product/recommendProductManagement/enableAndDisable`, // 批量启用和禁用
  queryDetails: `/api/product/recommendProductManagement/queryDetails`, // 获取详情
  updateRecommendProduct: `/api/product/recommendProductManagement/updateRecommendProduct`, // 批量编辑推荐位提交
  // 优惠劵管理
  DesignatedStoreTree: `/api/sys/storeManage/queryStores`, // 指定店铺接口
  getCouponList: `/api/product/productCoupon/coupon/query`, // 优惠劵列表
  DeleteCoupon: `/api/product/productCoupon/coupon/delete`, // 删除优惠劵
  EndCoupon: `/api/product/productCoupon/coupon/end`, //优惠券停止发放
  CouponExpire: `/api/product/productCoupon/coupon/expire`, // 优惠券失效
  CouponCopy: `/api/product/productCoupon/coupon/copy`, // 复制优惠劵
  addEditCoupon: `/api/product/productCoupon/coupon/insertOrUpdate`, //新建或编辑优惠劵
  getCouponDetail: `/api/product/productCoupon/coupon/detail`, // 获取优惠劵详情
  // 领取/使用记录
  getClaimAndUseRecord: `/api/product/productCoupon/couponsDetail`, // 领取/使用记录列表
  ClaimAndUseRecordExpire: `/api/product/productCoupon/expireCoupon`, // 运营后台使用户单张优惠券失效
  // 秒杀管理
  productPageBySpu: `/api/product/productGroup/productPageBySpu`, //商品分组-商品列表spu维度
  saveFlashSaleActivity: `/api/product/flashSaleManagement/saveFlashSaleActivity`, // 添加秒杀活动
  queryFlashSaleActivityList: `/api/product/flashSaleManagement/queryFlashSaleActivityList`, //秒杀活动列表
  stopActivityAndDel: `/api/product/flashSaleManagement/stopActivityAndDel`, // 秒杀活动列表-停止活动-删除
  flashSaleActivityEcho: `/api/product/flashSaleManagement/flashSaleActivityEcho`, // 秒杀活动编辑 回显
  activityProductEcho: `/api/product/flashSaleManagement/activityProductEcho`, // 秒杀管理-活动商品回显
  updateFlashSaleActivity: `/api/product/flashSaleManagement/updateFlashSaleActivity`, // 更新秒杀活动
  replication: `/api/product/flashSaleManagement/replication`, // 复制秒杀活动
  popularize: `/api/product/flashSaleManagement/popularize`, // 秒杀管理列表推广
  // 批量导出接口
  productBatchDownload: `/api/product/productBasic/spu/download`, // 商品
  attributeBatchDownload: `/api/product/productParamDict/download`, // 属性
  groupingBatchDownload: `/api/product/productGroup/download`, // 分組
  categoryBatchDownload: `/api/product/productCategory/download`, // 类目
  brandBatchDownload: `/api/product/productBrandInfo/download`, // 品牌
  // 批量导入接口
  productAttributeUpload: `/api/product/productParamDict/upload`, // 商品属性导入
  productBrandUpload: `/api/product/productBrandInfo/upload`, // 品牌导入
  productCategoryUpload: `/api/product/productCategory/upload`, // 商品类目导入
  productGroupUpload: `/api/product/productGroup/upload`, // 商品分组导入
  productGroupUploadSpu: '/api/product/productGroup/uploadSpu', // 商品导入
  // 错误下载
  productAttributeErrorDownload: `/api/product/productParamDict/download/error`, // 商品属性
  productBrandErrorDownload: `/api/product/productBrandInfo/download/error`, // 商品品牌
  productCategoryErrorDownload: `/api/product/productCategory/download/error`, // 商品类目
  productGroupErrorDownload: `/api/product/productGroup/download/error`, // 商品分组
  // 商品模块
  batchToChange: `/api/product/productBasic/batchToChange`, // 商品管理批量接口
  // 工作台
  queryBackendWorkbenchInfo: `/api/order/operationsBackendWorkbench/queryBackendWorkbenchInfo`, // 获取工作台数据总览、待审核、待发货、待售后总数
  queryStayAfterSales: `/api/order/operationsBackendWorkbench/queryStayAfterSales`, // 待售后table表格数据
  queryToBeShippedInfo: `/api/order/operationsBackendWorkbench/queryToBeShippedInfo`, // 待发货table表格数据
  // 订单管理
  logisticsDetailById: `/api/order/productOrder/logisticsDetailById`, // 订单详情-物流详情
  adminPanelUsers: "/api/sys/adminPanelUsers", // 查询运营后台用户列表
  // 砍价管理
  bargainGoods: `/api/product/productGroup/bargainGoods`, // 砍价-添加商品弹窗数据
  saveBargainActivity: `/api/product/productBargainManagement/saveBargainActivity`, // 添加砍价活动
  updateBargainActivity: `/api/product/productBargainManagement/updateBargainActivity`, //编辑砍价活动
  bargainCheck: `/api/product/productBargainManagement/bargainCheck`, // 砍价查看
  bargainCopy: `/api/product/productBargainManagement/bargainCopy`, // 砍价管理-复制
  bargainList: `/api/product/productBargainManagement/bargainList`, // 砍价管理列表
  bargainStopActivityAndDel: `/api/product/productBargainManagement/bargainStopActivityAndDel`, // 砍价管理活动列表-停止活动-删除
  bargainUserInfo: `/api/product/productBargainManagement/bargainUserInfo`, // 砍价用户列表
  queryByIdList: `/api/product/productCoupon/coupon/queryByIdList`, // 运营后台查询优惠券列表
  // 通用跳转链接->商品列表-按分组-> 查询对应商品数据
  searchThirdLevelGroupingProducts: `/api/product/productSearchManagement/searchThirdLevelGroupingProducts`,
  // 财务中心
  merchantFinanceDetail: '/api/order/orderFinance/merchant/financeDetail', // 商家收支明细
  // 版本管理
  delAppVersion: `/api/sys/delAppVersion`, // 删除版本信息
  // 邀请有礼
  AddInviteWithReward: `/api/product/inviteGiftManagement/insert`, // 新增邀请有礼
  UpdateInviteWithReward: `/api/product/inviteGiftManagement/update`, //编辑邀请有礼
  queryInviteWithRewardList: `/api/product/inviteGiftManagement/queryList`, // 邀请管理列表
  deleteInviteWithReward: `/api/product/inviteGiftManagement/delete`, // 删除邀请有礼
  stopInviteWithReward: `/api/product/inviteGiftManagement/end`, // 停止邀请有礼活动
  GeneralInviteRewardLinksList: `/api/product/inviteGiftManagement/queryListForAdd`, // 通用跳转链接的邀请有礼列表
  queryInviteWithRewardDetail: `/api/product/inviteGiftManagement/detail`, // 查询邀请有礼详情
  queryInviteWithRewardInviteStats: `/api/product/inviteGiftManagement/inviteStats`, // 邀请有礼数据统计
  InviteWithRewardCopy: `/api/product/inviteGiftManagement/copy`, // 邀请有礼复制
  // 店铺管理
  bindInvestmentManager: `/api/sys/storeInfo/bindInvestmentManager`, // 新增或更新招商负责人
  

  //汪文升
  // --------登录忘记密码模块
  login: "/api/oauth/token", // 登录授权 --带密码
  getScanCode: "/api/sys/register/getScanCode", // 获取唯一识别码
  getLogin: "/api/sys/register/getLogin", //获取移动端是否扫码确认了
  modiyPassWord: "/api/sys/register/modiyPassWord", // 重置密码 ---带密码
  // --------商品模块
  getProductList: "/api/product/productBasic/query", // 商品列表
  addLabel: "/api/product/productParamDict/insertParam", // 新增属性名 参数/规格  paramType 1 参数  2 规格
  addvalue: "/api/product/productParamDict/updateParamValue", // 新增属性值 参数/规格  paramType 1 参数  2 规格
  addEditProduct: "/api/product/productBasic/insertProduct", // 新增商品及编辑
  getCategoryTree: "/api/product/productCategory/tree", // 类目数据
  getProductBrands: "/api/product/productCategory/productBrands", // 品牌数据
  getParamDict: "/api/product/productParamDict/queryParam", // 查询参数、规格名称和值
  addBrand: "/api/product/productBrandInfo/insertBrandInfo", // 新增品牌
  batchUpdate: "/api/product/productBrandInfo/batchUpdate", // 批量添加品牌授权
  getProductDetail: "/api/product/productBasic/queryProductOne", // 获取商品详情
  getFreightMethod: "/api/sys/cxFreightTemplate/queryFreightInfoByStoreId", // 获取对应商品得店铺运费模板
  findLogisticsManageInfo: "/api/sys/cxLogistics/queryLogisticsManageInfo", // 查询物流信息

  // 刘李
  submit: "/api/sys/store/submit",
  queryList: "/api/sys/store/queryList",
  validateDeduplication: "/api/sys/store/validateDeduplication",
  sendEmailCode: "/api/sys/store/sendEmailCode", // 企业信息和店铺认证发送邮箱验证码
  sendPhoneCode: "api/sys/store/sendPhoneCode", // 发送手机验证码
  validateEmailCode: "/api/sys/store/validateEmailCode", // 邮箱验证码校验
  queryStatusInfo: "/api/sys/queryUserInfo", // 查询用户认证状态
  validatePhoneCode: "/api/sys/store/validatePhoneCode", // 校验手机验证码
  StoreVerificationReviewDetailsList: "/api/sys/companyStoreApproval/authReviewInformationList", // 店铺认证审核详情列表
  reviewInformationList: "/api/sys/companyStoreApproval/companyReviewInformationList", // 审核详情列表
  storeReviewInformationList: "/api/sys/companyStoreApproval/storeReviewInformationList", // 店铺审核-店铺资料-审核记录列表

  
  storeMerchantList: "/api/sys/companyStoreInfo/queryCompanyStoreAuthList", // 店铺认证列表
  StoreManagementList: "/api/sys/storeInfo/queryStoreInfoList", // 店铺管理列表
  storeReviewList: "/api/sys/companyStoreInfo/queryCompanyStoreAuthListInfo", // 店铺第一次认证详情
  approvedAndReject: "/api/sys/companyStoreApproval/storeAuthApproval", // 审核通过/驳回
  enterpriseReviewList: "/api/sys/companyModifyInfo/queryCompanyRecordList", // 企业信息审核列表
  storeCompanyAdminRecord: "/api/sys/companyModifyInfo/queryCompanyRecordInfo", // 企业信息审核详情
  companyApprovedAndReject: "/api/sys/companyStoreApproval/companyApproved", // 企业信息审核通过/驳回
  storeInfoList: "/api/sys/storeModifyInfo/queryStoreRecordList", // 店铺审核列表
  storeRecordInfoList: "/api/sys/storeModifyInfo/queryStoreRecordInfo", // 店铺信息详情
  storeApprovedAndReject: "/api/sys/companyStoreApproval/storeApproved", // 店铺审核-店铺资料-审核通过/驳回
  queryAuditLog: "/api/product/cxStoreAuthorization/queryAuditLog", // 查看店铺资质-品牌审核详情
  batchUpdateAuth: "/api/product/cxStoreAuthorization/batchUpdateAuth", // 批量操作通过-不通过

  // 以上 API 暂存，后期清理
  getCategoryTree: "/api/product/productCategory/tree", // 类目数据
  getAllCategoryTree: "/api/product/productCategory/categoryTree", // 类目数据
  getGroupTree: "/api/product/productGroup/goodsTree", // 分组数据
  getCategoryTableData: "/api/product/productCenter/queryProductCenter", // 类目列表数据
  getGroupTableData: "/api/product/productGroup/queryGoodsCenter", // 分组列表数据
  updateIdentify: "/api/product/productCenter/updateIdentify", // 类目启用禁用
  updateEnabled: "/api/product/productGroup/enabledType", // 禁用启用分组
  delCategory: "/api/product/productCenter/delProductCategory", // 类目删除
  delGroup: "/api/product/productGroup/delGoods", // 分组删除
  saveCategory: "/api/product/productCenter/saveAndUpdateProductCategory", // 新增or编辑类目
  getCategoryBrands: "/api/product/productCategory/queryBrandName", // 查对应类目关联的品牌
  getPageBrands: "/api/product/productBrandInfo/queryBrands", // 分页查询品牌
  updateDelete: "/api/product/productBrandInfo/updateDelete", // 品牌启用禁用
  getPageParams: "/api/product/productParamDict/queryParamList", // 分页查询属性
  getParamDetail: "/api/product/productParamDict/queryOne", // 查询属性详情
  updateParam: "/api/product/productParamDict/updateParamValue", // 编辑属性
  updateParamValueDelete: "/api/product/productParamDict/updateParamValueDelete", // 属性启用禁用
  saveGroup: "/api/product/productGroup/saveAndUpdateProductGroup", // 新增or编辑分组
  productGroupList: "/api/product/productGroup/productGroupList", // 商品列表
  getGroupEditList: "/api/product/productGroup/editList", // 选择商品列表
  storePage: "/api/product/bannerDetail/jumpQuery/storePage", // 店铺列表
 

  // 搜索管理API
  searchManagement: "/api/product/productSearchManagement/searchManagement", // 搜索热词列表
  submitSearch: "/api/product/productSearchManagement/submitSearch", // 提交搜索热词
  searchProductGroupList:
    "/api/product/productSearchManagement/searchProductGroupList", // 搜索管理商品分组列表

  // Banner管理API
  queryBannerList: "/api/product/banner/query", // 查询Banner列表
  insertOrUpdate: "/api/product/banner/insertOrUpdate", // 编辑Banner详情
  deleteBanner: "/api/product/banner/delete", // 删除Banner
  queryBannerDetail: "/api/product/bannerDetail/query", // 查询Banner详情
  queryProductListNoPage: "/api/product/productBasic/productList/nonPage", // 查询已勾选的商品列表
  shelfBannerStatus: "/api/product/banner/shelf", // 修改Banner上下架状态

  // 运营位管理API
  updateProductOperational:
    "/api/product/productOperational/updateProductOperational", // 新增/编辑运营位
  queryProductOperationalInfo:
    "/api/product/productOperational/queryProductOperationalInfo", // 查询运营位分页列表
  queryInfoById: "/api/product/productOperational/queryInfoById", // 查运营位详情
  updateProductOperationalPublish:
    "/api/product/productOperational/updateProductOperationalPublish", // 修改运营位状态
  operationCopy: "/api/product/productOperational/copy", // 复制
  orderManagement: "/api/order/order/orderManagement", // 订单列表
  orderDetails: `/api/order/b/orderManagement/orderDetails`, // 订单详情
  afterSaleList: "/api/order/afterSaleDetail/afterSaleList", // 售后列表
  afterSaleDetail: "/api/order/afterSaleDetail/afterSaleDetail", // 售后详情
  updateAfterSale: "/api/order/afterSaleDetail/update", // 平台修改售后并同意
  cancelAfterSale: "/api/order/afterSaleDetail/cancel", // 平台取消售后
  agreeAfterSale: "/api/order/afterSaleDetail/agree", // 平台同意售后
  afterSaleFeedBackList: "/api/order/applyAfterSalesProblem/problems", // 售后反馈列表
  replyAfterSales: "/api/order/applyAfterSalesProblem/reply", // 回复售后反馈
  operateAfterSales: "/api/order/applyAfterSalesProblem/operate", // 处理售后反馈
  getOperations: "/api/order/applyAfterSalesProblem/operations", // 处理记录
  batchSetAfterSaleDays: '/api/product/productCenter/updateAfterSalesTimeliness', // 批量设置类目售后时效
  batchUpdateCategory: "/api/product/productCenter/batchUpdate", // 类目管理批量设置资质-扣点
  queryStoreAuthorizationPageInfo: "/api/product/cxStoreAuthorization/queryPageInfo", // 分页查询店铺资质列表
  updateStoreInfoDiscount: "/api/sys/storeInfo/updateStoreInfoDiscount", // 批量设置折扣信息



  // 数据中心
  allDataView: "/api/order/stats/overview", // 数据总览
  transactionView: "/api/order/stats/transactionOverview", // 交易概况
  productOverview: "/api/product/stats/productOverview", // 商品洞察-商品概况
  productPayAmountRanking: "/api/product/stats/productPayAmountRanking", // 商品洞察-支付金额TOP
  productVisitsNumRanking: "/api/product/stats/productVisitsNumRanking", // 商品洞察-访问数TOP
  storeOverview: "/api/sys/stats/storeOverview", // 店铺洞察-店铺概况
  storeRanking: "/api/sys/stats/storeRanking", // 店铺洞察-店铺排行榜

  // 版本管理
  sysQueryPageInfo: '/api/sys/queryPageInfo', // 分页查询版本信息
  updateAppVersion: '/api/sys/updateAppVersion', // 新增-编辑版本信息
  queryCxAppVersionVo: '/api/sys/register/queryCxAppVersionVo', // app获取更新信息-版本号

  // 财务中心
  depositHistory: '/api/order/orderStoreDepositHis/depositHistory', // 运营后台-提现申请记录
  changeDepositHisStatus: '/api/order/orderStoreDepositHis/changeStatus', // 运营后台-标记提现申请打款状态
  cxStoreFinanceAccountQuery: '/api/sys/cxStoreFinanceAccount/query', // 门店财务账户信息查询
  platformFinanceDetail: '/api/order/orderFinance/platform/financeDetail', // 平台收支明细
  queryBusinessAccounts: '/api/order/orderFinance/merchant/accounts', // 查询商家账户

  uploadFile: `/api/sys/anon/c/upload`, // 上传文件
  exportOrderData: '/api/order/order/download', // 导出订单

  // im接口
  imAddStore: '/api/sys/imStoreBind/addStore', // im添加店铺
  imQueryList: '/api/sys/imStoreBind/queryList', // im查询店铺
  imRemoveStore: '/api/sys/imStoreBind/removeStore', // im删除店铺
  imQueryImOrderList: '/api/order/order/queryImOrderList', //im查询订单
  orderUpload: '/api/order/logistics/orderLogis/upload', // 导入订单
  orderUploadErrorDownload: '/api/order/logistics/orderLogis/download/error', // 导入订单失败导出
  imSend: '/api/sys/im/send', // 发送IM消息

  imAutomaticReplyQueryAutomaticReplyList: '/api/sys/imAutomaticReply/queryAutomaticReplyList', // 查询配置列表
  imAutomaticReplySaveAutomaticReply: '/api/sys/imAutomaticReply/saveAutomaticReply', // 保存自动回复
  imAutomaticReplyEnabledAutomaticReply: '/api/sys/imAutomaticReply/enabledAutomaticReply', // 禁用自动回复

  productModuleList: '/api/product/productModule/list', // 商城管理，商品组件列表
  productModuleDetail: '/api/product/productModule/detail', // 商城管理，商品组件详情
  changeProElStatus: '/api/product/productModule/changeStatus', // 商城管理，商品组件 上下架
  delProEl: '/api/product/productModule/delete', // 商城管理，商品组件 删除
  editProEl: '/api/product/productModule/update', // 商城管理，商品组件 编辑
  addProEl: '/api/product/productModule/insert', // 商城管理，商品组件 新增
  // 财务中心接口
  cxStoreFinanceAccountAccountList: '/api/sys/cxStoreFinanceAccount/accountList', // 银行账户审核列表
  cxStoreFinanceAccountAuditRecord: '/api/sys/cxStoreFinanceAccount/auditRecord', // 银行账户审核记录
  cxStoreFinanceAccountAudit: '/api/sys/cxStoreFinanceAccount/audit', // 审核银行账户
  freightUpload: '/api/order/orderStoreFreightSettleHis/freight/upload', // 运费月结单导入
  freightDownloadError: '/api/order/orderStoreFreightSettleHis/freight/download/error', // 上传错误运费月结导出
  freightRecord: '/api/order/orderStoreFreightSettleHis/freight/record', // 运费月结单上传历史分页
  freightDownloadHis: '/api/order/orderStoreFreightSettleHis/freight/download/his', // 指定运费月结单下载
  batchUpdataPrice: '/api/product/b/productBasic/batchToChangeWeb', // 批量修改 零售价 划线价 成本价
  skuCanDisableCheck: '/api/product/productBasic/productSkuOffShelf', // sku是否可以禁用查询
  orderAddPlatformRemark: '/api/order/addPlatformRemark', // 订单中心-订单列表-详情-添加平台备注
  productHomePageCouponQueryInfo: '/api/product/productHomePageCoupon/queryInfo', // 获取首页优惠券
  productHomePageCouponUpdateProductHomePageCoupon: '/api/product/productHomePageCoupon/updateProductHomePageCoupon', // 新增-修改数据 
  productHomePageCouponUpdateStatus: '/api/product/productHomePageCoupon/updateStatus', // 启用-禁用数据
  operateQuery: '/api/product/flashComponent/operate/query', // 运营查询秒杀组件
  flashComponentInsertOrUpdate: '/api/product/flashComponent/insertOrUpdate', // 新建或编辑秒杀组件
  uploadOrderUser: '/api/product/productCoupon/coupon/upload', // 导入订单【订单中包含用户】 发放优惠券
  saveCouponShareInfo: '/api/product/productCoupon/coupon/updateShareInfo', // 更新优惠券分享图 分享title 分享描述
  expireUserCoupon: '/api/product/productCoupon/expireUserCoupon', // 优惠券失效

  //  推送管理
  imPushTaskQueryTaskList: '/api/sys/imPushTask/queryTaskList', // 查询列表
  imPushTaskQueryTask: '/api/sys/imPushTask/queryTask', // 查询单个
  imPushTaskSave: '/api/sys/imPushTask/save', //保存
  imPushTaskDelete: '/api/sys/imPushTask/delete', // 删除
  imPushTaskManualPush: '/api/sys/imPushTask/manualPush', // 立即推送
  uploadMission: '/api/product/brokerageMission/uploadMission', // 导入返佣任务
  refund: '/api/order/productOrder/refund', //运营后台 未发货订单退款

  //  用户管理
  getUserManageList: '/api/sys/userManageList', // 用户列表
  setUserTaskWhitelist: '/api/sys/operateBrokerageWhiteList' // 添加/移除返佣白名单
};

export default apis;
